// 软件信息管理
<template>
    <div class="softwareInfoManage baseBg">
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     ref="searchForm"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="部门名称">
                    <el-input v-model="searchForm.deptName"
                              placeholder="请输入部门名称"
                              clearable />
                </el-form-item>
                <!-- <el-form-item label="层级">
                    <el-select v-model="searchForm.level"
                               placeholder="请选择层级">
                        <el-option v-for="(item,index) in levelList"
                                   :key="index"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->

                <el-button type="primary"
                           @click="search">搜索</el-button>
                <el-button type=""
                           @click="resetSearchForm">清除</el-button>
                <el-button type="success"
                           class="flr"
                           @click="addNew">新增部门</el-button>
            </el-form>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData"
                          border
                          :height="tableHeight"
                          v-loading="isloading"
                          row-key="id"
                          stripe
                          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column prop="deptName"
                                     label="名称">
                    </el-table-column>
                    <el-table-column prop="level.text"
                                     width="200"
                                     label="层级">
                    </el-table-column>

                    <el-table-column prop="createTime"
                                     width="300"
                                     label="创建日期">
                        <template slot-scope="scope">
                            {{commonJs.timestampToTime(scope.row.createTime) || ''}}
                        </template>
                    </el-table-column>

                    <el-table-column prop="userCount"
                                     width="200"
                                     label="人数">
                    </el-table-column>

                    <el-table-column label="操作"
                                     width="200">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="addNewChildren(scope.row,scope.row.id)">新增子节点</el-link>
                            <el-link type="success"
                                     @click="editTable(scope.row)">编辑</el-link>
                            <el-popconfirm confirmButtonText='确认'
                                           cancelButtonText='取消'
                                           icon="el-icon-info"
                                           iconColor="red"
                                           @confirm="deleteTable(scope.row)"
                                           title="确定删除吗？">
                                <el-link slot="reference"
                                         type='danger'>删除</el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <!-- <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" /> -->
            </div>
        </div>
        <popAddOrgan v-if="systemDialogVisible"
                     :formData="rowData"
                     :title="popTitle"
                     @close="closeSystemDialog"
                     @refresh="search" />
    </div>
</template>

<script>
import systemManageApi from "@/api/systemManageApi.js";
// import Pagination from "@/components/Pagination"; // 分页
import popAddOrgan from "./components/organManagement/popAddOrgan";
export default {
    name: "organManagement",
    props: [],

    components: {
        // Pagination,
        popAddOrgan,
    },

    data() {
        return {
            systemDialogVisible: false,
            popTitle: "",
            searchForm: {
                deptName: null,
                page: 1,
                size: 20,
                level: "", //层级
            },
            //部门层级
            levelList: [
                {
                    label: "一级",
                    value: "ONE_LEVEL",
                },
                {
                    label: "二级",
                    value: "TWO_LEVEL",
                },
                {
                    label: "三级",
                    value: "THREE_LEVEL",
                },
                {
                    label: "四级",
                    value: "FOUR_LEVEL",
                },
            ],
            // pageTotal: 0,
            isloading: true,
            rowData: null,
            tableData: [
                {
                    name: "systemManager",
                    id: "1326405369764057090",
                    parentId: "",
                    path: "/system",
                    icon: "icon.png",
                    title: "系统管理",
                    type: {
                        text: "模块",
                    },
                    noAuthorMethod: null,
                    ord: 1,
                    level: null,
                    children: [
                        {
                            name: "userManager",
                            id: "1326405913660428289",
                            parentId: "1326405369764057090",
                            path: "/user",
                            icon: "user.png",
                            title: "用户管理",
                            type: {
                                text: "页面",
                            },
                            noAuthorMethod: null,
                            ord: 2,
                            level: null,
                            children: [
                                {
                                    name: "deptManager",
                                    id: "1326406851888484354",
                                    parentId: "1326405913660428289",
                                    path: "/dept",
                                    icon: "dept.png",
                                    title: "部门管理",
                                    type: {
                                        text: "详情",
                                    },
                                    noAuthorMethod: null,
                                    ord: 5,
                                    level: null,
                                },
                                {
                                    name: "userDetailManager",
                                    id: "1326407059124850690",
                                    parentId: "1326405913660428289",
                                    path: "/userDetail",
                                    icon: "userDetail.png",
                                    title: "用户页面",
                                    type: {
                                        text: "详情",
                                    },
                                    noAuthorMethod: null,
                                    ord: 6,
                                    level: null,
                                    children: [
                                        {
                                            name: "editUserManager",
                                            id: "1326407966776430593",
                                            parentId: "1326407059124850690",
                                            path: "/editUser",
                                            icon: "editUser.png",
                                            title: "编辑用户",
                                            type: {
                                                text: "功能权限",
                                            },
                                            noAuthorMethod: {
                                                text: "不可编辑",
                                            },
                                            ord: 1,
                                            level: null,
                                        },
                                        {
                                            name: "addUserManager",
                                            id: "1326407650790150145",
                                            parentId: "1326407059124850690",
                                            path: "/addUser",
                                            icon: "addUser.png",
                                            title: "新增用户",
                                            type: {
                                                text: "功能权限",
                                            },
                                            noAuthorMethod: {
                                                text: "隐藏",
                                            },
                                            ord: 7,
                                            level: null,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            name: "roleManager",
                            id: "1326406137216831489",
                            parentId: "1326405369764057090",
                            path: "/role",
                            icon: "role.png",
                            title: "角色管理",
                            type: {
                                text: "页面",
                            },
                            noAuthorMethod: null,
                            ord: 3,
                            level: null,
                        },
                        {
                            name: "menuManager",
                            id: "1326406362098634753",
                            parentId: "1326405369764057090",
                            path: "/menu",
                            icon: "menu.png",
                            title: "菜单管理",
                            type: {
                                text: "页面",
                            },
                            noAuthorMethod: null,
                            ord: 4,
                            level: null,
                        },
                    ],
                },
            ],
        };
    },

    created() {},

    mounted() {
        this.search();
    },

    methods: {
        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            this.isloading = true;
            systemManageApi
                .getDepartmentTable(searchForm)
                .then((res) => {
                    this.isloading = false;
                    this.tableData = res.content || [];
                    // this.pageTotal = res.content.total || 0;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        details(e) {
            this.$router.push({
                path: "/baseInfoManage/systemDetail",
                query: { systemInfo: JSON.stringify(e) },
            });
        },
        // 搜索
        search() {
            this.searchForm.page = 1;
            this.getList();
        },

        //清空筛选条件
        resetSearchForm() {
            this.searchForm.deptName = "";
            this.getList();
        },
        // 新增
        addNew() {
            this.rowData = { isRootAdd: true };
            this.popTitle = "新增组织架构";
            this.systemDialogVisible = true;
        },
        // 新增子节点
        addNewChildren(data) {
            this.popTitle = "新增子组织架构";
            this.rowData = Object.assign({}, data, {
                pid: data.id,
                id: null,
                deptName: "",
            });
            this.systemDialogVisible = true;
        },

        // 表格的编辑操作
        editTable(data) {
            this.popTitle = "编辑组织架构";
            this.rowData = Object.assign(data, { pid: data.parentId });
            this.systemDialogVisible = true;
        },

        // 新增或編輯系統彈框 关闭
        closeSystemDialog() {
            this.systemDialogVisible = false;
        },

        // 表格的删除操作
        deleteTable(row) {
            this.isloading = true;
            systemManageApi
                .deleteDepartment(row.id)
                .then(() => {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    this.getList();
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //修改状态
        changeStatus(row) {
            console.log(row);
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 195 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
