// 新增部门
<template>
    <el-dialog :close-on-click-modal="false"
               :title="title"
               :visible="true"
               width="700px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="100px"
                 :inline="false"
                 size="normal">
            <el-form-item label="上级部门"
                          v-if="!form.isRootAdd">
                <!-- <el-select v-model="form.pid"
                           placeholder="请选择上级部门">
                    <el-option v-for="item in orgList"
                               :key="item.id"
                               :label="item.label"
                               :value="item.id">
                    </el-option>
                </el-select> -->
                <selectTree :treeData="orgList"
                            :shouldClean="true"
                            @handClear="clearProvider"
                            placeholderStr="请选择上级部门"
                            :checkedInfo="{value:form.pid,label:''}"
                            @treeSelectNodeClick="treeSelectNodeClick"></selectTree>
            </el-form-item>
            <el-form-item label="部门名称">
                <el-input v-model="form.deptName"
                          placeholder="请输入部门名称"
                          clearable />
            </el-form-item>
            <el-form-item label="部门负责人">
                <el-select v-model="form.deptHead"
                           filterable
                           placeholder="请选择部门负责人">
                    <el-option v-for="item in userList"
                               :key="item.code"
                               :label="item.label"
                               :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>
<script>
import systemManageApi from "@/api/systemManageApi.js";
import commonAPI from "@/api/commonAPI.js";
import selectTree from "@/components/selectTree";
export default {
    name: "popAddOrgan",
    components: {
        selectTree,
    },
    props: {
        title: {
            type: String,
            default: "新增",
        },
        formData: {
            type: Object,
            default: null,
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, newVal)
                    ));
            },
        },
    },
    data() {
        return {
            form: {
                deptName: "", //组织名称
                pid: "", //父级id
                deptHead: "", //部门负责人
            },
            orgList: [], //组织机构列表
            userList: [], //人员列表
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入系统名称",
                    },
                ],
            },
        };
    },
    mounted() {
        this.getAllUserList();
        this.getOrgList();
    },
    methods: {
        //获取人员列表
        getAllUserList() {
            commonAPI.getAllUserList().then((res) => {
                this.userList = res.content;
            });
        },

        //获取组织结构下拉
        getOrgList() {
            commonAPI.getOrgList().then((res) => {
                this.orgList = res.content;
            });
        },

        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.createDeviceType();
                    // this.$emit("close");
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        //保存组织架构
        createDeviceType() {
            this.isloading = true;
            systemManageApi
                .saveDepartment(this.form)
                .then(() => {
                    this.$message({ message: "操作成功", type: "success" });
                    this.isloading = false;
                    this.$emit("close");
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //组织部门点击回调
        treeSelectNodeClick(data) {
            this.form.pid = data.id;
        },

        //组织部门清空
        clearProvider() {
            this.form.pid = "";
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
    computed: {},
};
</script>
<style scoped>
</style>